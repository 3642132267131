@import url('https://fonts.googleapis.com/css2?family=Montez&family=Passion+One&family=Quicksand:wght@500&display=swap&family=Monospace');
.fn-name-purple {
  color: rgb(255, 71, 194);
}
.fn-name-blue {
  color: rgb(71, 120, 255);
}

.fn-name-yellow {
  color: rgb(255, 206, 71);
}

html,
body {
  overflow-x: hidden;
  margin: 0;
}

html {
  height: auto;
}
body {
  height: 100% !important;
}
